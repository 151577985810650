<script>
import {Dialog} from "vant";
import {getTeacherIdFromSessionStorage} from "@/util/getTeacherId";
import {getIndexPathFromStorage} from "@/util/getInitRoutePath";

const oss = "https://shengjin.oss-cn-hangzhou.aliyuncs.com/weizhen/common/item";
const vip1 = oss + "/live_seat.jpg" + "?random=" + Date.now();
export default {
  data() {
    return {
      good: null,
      pageHeight: 0,
      pageWidth: 0,
      displayVipImage: vip1,
      resizeBind: this.resize.bind(this),
      teacher_id: "999999",
      goodsList: [],
      isOver: false,
      ordinary: oss + "/ordinary_seat.jpg" + "?random=" + Date.now()
    }
  },
  mounted() {
    this.getGoodsList();
    this.resize();
    window.addEventListener("resize", this.resizeBind);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.resizeBind);
  },
  methods: {
    async getGoodsList() {
      let res = await this.$axios.get(`/item/getItemList?teacher_id=${this.teacher_id}`);
      this.goodsList = res.data;
    },
    resize() {
      const tabbarSize = document.querySelector(".van-tabbar").getBoundingClientRect();
      const headerSize = document.querySelector(".van-nav-bar").getBoundingClientRect();
      const pageHeight = window.innerHeight;
      this.pageWidth = window.innerWidth;
      const currentPageHeight = pageHeight - tabbarSize.height - headerSize.height;
      console.log(currentPageHeight);
      this.pageHeight = currentPageHeight;
      // 获取屏幕高度的一半

    },
    buyClick(good) {
      // this.$emit("buyClick", good);
      switch (good.jump_type) {
        case 1:
          this.$router.push({
            name: "pay",
            query: {
              id: good.id,
              teacher_id: this.teacher_id,
            },
          });
          break;
        case 2:
          window.location.href = good.jump_param;
          break;
        default:
          Dialog.alert({
            title: "商品跳转参数异常",
          }).then(() => {
            this.$router.replace(getIndexPathFromStorage());
          });
          break;
      }
    }
  }
}
</script>


<template>
  <div class="page" :style="{height: pageHeight + 'px'}">
    <div v-if="!isOver">
      <img :src="displayVipImage" @click="buyClick(goodsList[0])" :style="{height: pageHeight * 0.5 - 5 + 'px'}">
      <img :src="ordinary" @click="buyClick(goodsList[1])" :style="{height: (pageHeight * 0.5) - 5 + 'px'}">
    </div>
    <div v-else>
      <div class="h-full flex flex-col items-center justify-center overflow-hidden py-3">
        <p class="mb-3">2024年10月19日入场券已售罄</p>
        <p class="mb-3">感谢您一直关注与支持。</p>
        <p class="mb-3">精彩已满座，期待再相遇！</p>
        <p class="mb-3">如有疑问请点击下方联系客服</p>
        <div class="px-5 w-full">
          <van-button type="primary" block url="tel:13107873675">联系客服</van-button>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
img {
  width: 100%;
  object-fit: fill;
}

.page {
  @apply px-3;
}

</style>